import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/zhannum/git/the-dark-between/src/layouts/DefaultLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`They are quite depressing, aren't they? I had a Great Uncle that was a Fade. Strangest thing—there wasn't a hint of color in his eyes. Other than that you would never have known. He didn't do much, just sat around skulking most of the time. Though perhaps he was just a boring person, even before his thread was severed.`}</em></p>
    <div style={{
      "textAlign": "right"
    }}>—Mekhag Ardashessian, Memoirs of the Luminancer</div>
    <p>{`Fades—or, the Faded—whether willingly or forcibly, have their `}<a parentName="p" {...{
        "href": "/Thread",
        "title": "Thread"
      }}>{`tether`}</a>{` to `}<a parentName="p" {...{
        "href": "/Luminous%20Magic",
        "title": "Luminous Magic"
      }}>{`Luminous Magic`}</a>{` severed, rendering them unable to use Magic or be touched by it in any way.`}</p>
    <h3>{`Just Like Us`}</h3>
    <p>{`In some cases, Fades tend to feel less emotion than other humans. Without conscious effort, they find themselves drawn towards quiet places and distance themselves from others. On the whole, however, Fades retain much of their original personality from before they where changed. Although their soul, their spiritual connection to the source of `}<a parentName="p" {...{
        "href": "/Luminous%20Magic",
        "title": "Luminous Magic"
      }}>{`Luminous Magic`}</a>{` has been severed, their human qualities remain.`}</p>
    <h3>{`Above and Beyond`}</h3>
    <p>{`Some Fades strive to overcome their lack of Magic by becoming experts in their field. Skilled blacksmiths, mathematicians, historians, warriors, engineers, and more. Their drive to make the most out of what they have and overcome their shortcomings gives them extraordinary determination.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      